<template>
  <div class="root">
    <el-image :src="url" fit="cover"></el-image>
    <p class="nav">
      <span @click="$router.replace('/index')">点此返回首页</span>
    </p>
  </div>
</template>

<script>
import Error404 from "@/assets/img/404.svg";
export default {
  data() {
    return {
      url: Error404,
    };
  },
};
</script>

<style lang="less" scoped>
.root {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-top:150px;
  .el-image {
    width: 300px;
    height: 300px;
    display: block;
  }
  .nav {
    text-align: center;
    span:hover {
      cursor: pointer;
      color: #0079fe;
    }
  }
}
</style>